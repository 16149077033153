import { resolveRoute } from "vike/routing";

export const route = (pageContext) => {
  const tagView = resolveRoute("/users/@username/library/tags/@uuid", pageContext.urlPathname);
  if (tagView.match) return tagView;

  const statusView = resolveRoute("/users/@username/library*", pageContext.urlPathname);
  if (!statusView.match) return false;

  statusView.routeParams.status = statusView.routeParams["*"].slice(1) || "finished";
  return statusView;
};
